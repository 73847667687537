import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ActionsKeys from '../store/actionsKeys';

export default function ToggleBarraBusca({ tipo }) {
	const dispatch = useDispatch();
	const { filtroBusca } = useSelector(state => state);

	const [classToggle, setClassToggle] = useState();
	const [tipoPilula, setTipoPilula] = useState();

	useEffect(() => {
		const isTipoPadrao = tipo === 'padrao';

		setClassToggle(isTipoPadrao ? 'alinha-direita' : 'left-buttons');
		setTipoPilula(isTipoPadrao ? 'pilula-padrao' : 'pilula-busca')
	}, []);

	const dadosToggle = ["Local", "Empresa"];

	const setFiltroBusca = filtro => dispatch({ type: ActionsKeys.SET_FILTRO_BUSCA, filtro });

	const opcoesToggle = dadosToggle.map((dado, index) => {
		const classes = `btn center ${filtroBusca == index ? 'active' : ''}`;

		return (
			<div className={classes} onClick={() => setFiltroBusca(index)} key={index} >
				<span>{dado}</span>
			</div>
		);
	});

	return (
		<section id="sec-toggle-busca" className={`${classToggle} center`} data-toggle={filtroBusca}>
			<div className={`pilula ${tipoPilula}`}>
				{opcoesToggle}
			</div>
		</section>
	);
}