import React from 'react';
import DadosCredenciado from "./DadosCredenciado";
import Sidebar from "./Sidebar";

export default function Conteudo({ credenciado }) {
    return (
        <div className="row corpo-perfil large-wrapper no-gutters">
            <DadosCredenciado
                apresentacao={credenciado.observacao}
                fotos={credenciado.imagemList}
                servicos={credenciado.servicoMap}
                conveniencias={credenciado.diferencialMap}
            />

			<Sidebar credenciado={credenciado}/>
        </div>
    );
}