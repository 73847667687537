import { _aplicaFiltrosSelecionados } from "../busca/filtro-helper";

/* TOGGLE DOS FILTROS */
const adicionaLista = (lista, item) => lista.concat(item);

const removeLista = (lista, item) => lista.filter(i => i !== item);

const toggleItem = (lista, itemToggle) => lista.indexOf(itemToggle) > -1 ? removeLista(lista, itemToggle) : adicionaLista(lista, itemToggle);

/* ORDENAÇÃO DE RESULTADOS */
const ordenaCredenciadosPorProp = (credenciados, prop) => {
	const limiteLoop = credenciados.length;

	for (let i=0; i<limiteLoop; i++) {
		for (let j=0; j<limiteLoop; j++) {
			const obj = credenciados[i];
			const proxObj = credenciados[j];

			if (obj[prop] < proxObj[prop]) {
				credenciados[i] = proxObj;
				credenciados[j] = obj;
			}
		}
	}
	
	return credenciados.map(cred => cred.credenciado);
}

const ordenaPorDistancia = credenciados => {
	let mapaDistanciaObj = [];
	credenciados.forEach(credenciado => mapaDistanciaObj.push({ distancia: parseFloat(credenciado.distancia).toFixed(2), credenciado: credenciado }));

	return ordenaCredenciadosPorProp(mapaDistanciaObj, 'distancia');
}

const ordenaPorOrdemAlfabetica = credenciados => {
	let mapaNomeObj = [];
	credenciados.forEach(credenciado => mapaNomeObj.push({ nome: credenciado.razaoSocial.toUpperCase() || credenciado.nomeFantasia.toUpperCase(), credenciado: credenciado }));

	return ordenaCredenciadosPorProp(mapaNomeObj, 'nome');
}

/* PREPARAR RESULTADOS PARA EXIBIÇÃO */
const ordenaResultados = (ordenacao, resultados) => {
	ordenacao = parseInt(ordenacao);

	if (ordenacao === 2 ) {
		return ordenaPorDistancia(resultados);
	} else {
		resultados = ordenaPorOrdemAlfabetica(resultados);

		if (ordenacao === 1) {
			return resultados.reverse();
		} else {
			return resultados;
		}
	}
}

const aplicaFiltrosResultados = ({ resultadosBusca, convenienciasSelecionadas, servicosSelecionados, ordenacaoSelecionada }) => {
	const filtrados = _aplicaFiltrosSelecionados(resultadosBusca, convenienciasSelecionadas, servicosSelecionados).filter(credenciado => !credenciado.hidden);

	return ordenaResultados(ordenacaoSelecionada, filtrados);
}

const getResultadosBusca = credenciados => {
	let resultadosBusca = [];

	credenciados.forEach(credenciado => {
		if (credenciado.resultadoPesquisa) {
			credenciado.diferencialMap = credenciado.diferencialMap || [];
			credenciado.servicoMap = credenciado.servicoMap || [];

			resultadosBusca.push(credenciado);
		}
		
		if (credenciado.filialList && credenciado.filialList.length) {
			const filiaisResultado = credenciado.filialList.filter(filial => filial.resultadoPesquisa);

			filiaisResultado.forEach(filial => {
				filial.diferencialMap = credenciado.diferencialMap;
				filial.servicoMap = credenciado.servicoMap;

				resultadosBusca.push(filial);
			});
		}
	});

	return resultadosBusca;
}

/* CONTADOR POR FILTRO */
const getResultadosFormatados = resultadosBusca => resultadosBusca.map(resultado => {
	const { codigo, diferencialMap, servicoMap } = resultado;
	const conveniencias = diferencialMap ? Object.keys(diferencialMap).map(conv => parseInt(conv)) : [];
	const servicos = servicoMap ? Object.keys(servicoMap).map(serv => parseInt(serv)) : [];

	return {
		codigo,
		conveniencias,
		servicos
	}
});

const getItensPorFiltros = resultados => {
	let resultadosPorConveniencia = {};
	let resultadosPorServico = {};

	getResultadosFormatados(resultados).forEach(resultado => {
		if (resultado.conveniencias) {
			resultado.conveniencias.forEach(conv => {
				let possui = resultadosPorConveniencia[conv] !== undefined;
				
				resultadosPorConveniencia[conv] = possui ? resultadosPorConveniencia[conv] + 1 : 1;
			});
		}

		if (resultado.servicos) {
			resultado.servicos.forEach(serv => {
				let possui = resultadosPorServico[serv] !== undefined;
				
				resultadosPorServico[serv] = possui ? resultadosPorServico[serv] + 1 : 1;
			});
		}
	});

	return {
		resultadosPorConveniencia,
		resultadosPorServico
	};
}

function getQuantidadeResultadosPorFiltros(state) {
	const { resultadosPorConveniencia, resultadosPorServico } = getItensPorFiltros(state.resultadosBusca);

	return { ...state, resultadosPorConveniencia, resultadosPorServico};
}

export {
	getResultadosBusca,
	aplicaFiltrosResultados,
	ordenaResultados,
	getQuantidadeResultadosPorFiltros,
	toggleItem,
}