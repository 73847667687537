import React from 'react';
import IcnsAPI from '../../util/iconesAPI';

export default function EmailCredenciado(props) {
    const isEmail = props.email && props.email !== '';

    if (!isEmail) return null;

    return (
        <div className="email-empresa row col-12">
            <span>{IcnsAPI['email']}</span>

            <span className="conjunto-dados col-lg-10 col-10" data-tooltip-big={props.email}>
                <p className="txt-principal truncate">{props.email.pathname ? props.email.pathname = '' && props.email : props.email}</p>
            </span>
        </div>
    )
}