import React, { useEffect, useState } from 'react';

export default function TogglePadrao(props) {
    const dadosToggle = [
        {icone: props.icone1, content: props.opcao1, value: '0'},
        {icone: props.icone2, content: props.opcao2, value: '1'},
    ];
    
    const padrao = {
        classEstrutura: 'alinha-direita',
        tipo: 'pilula-padrao',
    };

    const busca = {
        classEstrutura: 'left-buttons col-12 col-sm-4 col-md-5 col-lg-4 col-xl-3',
        tipo: 'pilula-busca',
    };

    const modeloToggle = (props.tipo === 'padrao') ? padrao : busca;
    const [valor, setValor] = useState(props.defaultState);

    useEffect(() => {
        props.alteracaoEstado && props.alteracaoEstado((valor === '0') ? '0' : '1');
    }, [valor]);

    function addAtivo(e) {
        setValor(e.target.closest('.btn').getAttribute('data-valor'));

        if (props.callbackClick) {
            props.callbackClick(e);
        } else {
            setValor(e.target.closest('.btn').getAttribute('data-valor'));
        }
    }

    const estruturaToggle = dadosToggle.map((dado) => 
        <div className={`btn center ${dado.value === valor ? 'active' : ''}`} data-valor={dado.value} onClick={addAtivo} key={dado.value}>
            {dado.icone}
            {dado.content && <span>{dado.content}</span>}
        </div>
    );
 
    return (
        <section data-toggle={valor} id={props.id} className={`${modeloToggle.classEstrutura} ${props.Class}`}>
            <div className={`pilula ${modeloToggle.tipo}`}>
                {estruturaToggle}
            </div>
        </section>
    )
}