import ActionsKeys from './actionsKeys';
import { toggleItem, getResultadosBusca, aplicaFiltrosResultados, ordenaResultados, getQuantidadeResultadosPorFiltros } from './action-helper';

export default function processaAction(state, action) {
	switch(action.type) {
		case ActionsKeys.TOGGLE_PESQUISANDO:
			return { ...state, isPesquisando: action.isPesquisando, isCarregando: action.isPesquisando };

		case ActionsKeys.SET_CONVENIENCIAS:
			return { ...state, conveniencias: action.conveniencias };

		case ActionsKeys.SET_SERVICOS:
			return { ...state, servicos: action.servicos };

		case ActionsKeys.SET_KEY_MAPS:
			return { ...state, keyMaps: action.keyMaps };

		case ActionsKeys.SET_DISTANCIA:
			return { ...state, distanciaSelecionada: action.distancia };

		case ActionsKeys.SET_LATITUDE:
			return { ...state, latitudeBusca: action.lat ? action.lat : -12.855983 };

		case ActionsKeys.SET_LONGITUDE:
			return { ...state, longitudeBusca: action.lng ? action.lng : -51.5476717 };

		case ActionsKeys.SET_RESULTADOS:
			state = { ...state, resultadosBusca: getResultadosBusca(action.credenciados), ordenacaoSelecionada: state.filtroBusca === 0 ? 2 : 0 };
			state = getQuantidadeResultadosPorFiltros(state);

			return { ...state, resultadosFiltrados: aplicaFiltrosResultados(state) };

		case ActionsKeys.TOGGLE_CONVENIENCIA:
			state = { ...state, convenienciasSelecionadas: toggleItem(state.convenienciasSelecionadas, action.idConveniencia) };
			return { ...state, resultadosFiltrados: aplicaFiltrosResultados(state) };

		case ActionsKeys.LIMPA_CONVENIENCIAS:
			state = { ...state, convenienciasSelecionadas: [] };
			return { ...state, resultadosFiltrados: aplicaFiltrosResultados(state) };

		case ActionsKeys.TOGGLE_SERVICO:
			state = { ...state, servicosSelecionados: toggleItem(state.servicosSelecionados, action.idServico) };
			return { ...state, resultadosFiltrados: aplicaFiltrosResultados(state) };

		case ActionsKeys.LIMPA_SERVICOS:
			state = { ...state, servicosSelecionados: [] };
			return { ...state, resultadosFiltrados: aplicaFiltrosResultados(state) };

		case ActionsKeys.SET_ORDENACAO:
			return { ...state, ordenacaoSelecionada: action.ordenacao, resultadosFiltrados: ordenaResultados(action.ordenacao, state.resultadosFiltrados) };

		case ActionsKeys.SET_FILTRO_BUSCA:
			return { ...state, filtroBusca: action.filtro };

		case ActionsKeys.SET_TERMO_BUSCA:
			return { ...state, termoBusca: action.termo };

		case ActionsKeys.SET_TIPO_VISUALIZACAO:
			return { ...state, tipoVisualizacao: action.tipo };

		case ActionsKeys.TOGGLE_FILTROS:
			return { ...state, isFiltrosExibidos: action.exibir };

		case ActionsKeys.SET_CREDENCIADO_PERFIL:
			return { ...state, credenciadoPerfil: action.credenciado };

		case ActionsKeys.SET_CODIGO_CARD_ATIVO:
			return { ...state, codigoCardAtivo: action.codigo };

		case ActionsKeys.SET_MARCADORES_MAPA:
			return { ...state, marcadores: action.marcadores };

		case ActionsKeys.SET_CENTRO_MAPA:
			return { ...state, centroMapa: action.latlng };

		case ActionsKeys.TOGGLE_INFO_WINDOW:
			return { ...state, isInfoWindowVisivel: action.visivel};

		case ActionsKeys.SET_INFO_WINDOW:
			return { ...state, infoWindow: action.infoWindow }

		default:
			return state;
	}
}