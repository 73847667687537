import React, { useState, useEffect, useRef } from 'react';
import ItemFiltro from './ItemFiltroOpcoes.js'
import { useDispatch } from 'react-redux';
import ActionsKeys from '../store/actionsKeys';

export default function FiltroOpcoes (props) {
	const dispatch = useDispatch();

    const [state, setState] = useState('collapsed');

	const expand = () => state ? setState('') : setState('collapsed');
	const limparFiltros = () => dispatch({ type: ActionsKeys[`LIMPA_${props.tipoFiltro.toUpperCase()}`] });

    const itensFiltro = props.lista && props.lista.map((item, index) => {
        return (
            <ItemFiltro
                item={item}
                classe={props.classe}
				onClickOpcao={props.onClickOpcao}
                key={index}
                tipoFiltro={props.tipoFiltro}
                isChecked={props.selecao.includes(item.codigo)}
                quantidadeResultados={props.quantidadePorItem[item.codigo] || 0}
            />
        );
    });

    return (
        <div id={props.id} className="componente-filtro">
			<span className="tipo-filtro">{props.nome}</span>

            <span className="lnk-limpar" onClick={limparFiltros}>limpar</span>

            <section className={`container-filtros ${state}`}>
                {itensFiltro}
            </section>

			{state && 
				<span className="expand-filtros" onClick={expand}>{props.expandir}</span>
			}

			{!state && 
				<span className="expand-filtros" onClick={expand}>Ocultar {props.nome}</span>
			}
        </div>
    );
}