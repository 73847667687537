import React, { useEffect, useState } from 'react';
import IcnsAPI from '../util/iconesAPI';
import { useSelector, useDispatch } from 'react-redux';
import ActionsKeys from '../store/actionsKeys';

// const ID_ESTILO_BARRA_BUSCA_EXTERNA = 'barra-busca-externa';
// const ID_ESTILO_BARRA_BUSCA_INTERNA = 'barra-busca-resultados';

export default function BotaoBarraBusca({ id, className, idEstilo }) {
	const dispatch = useDispatch();

	const {
		termoBusca
	} = useSelector(state => state);

	// const URL_TELA_RESULTADO = 'https://ec2-34-207-218-168.compute-1.amazonaws.com/resultado-busca-socnet';
	const classTipoBotao = 'col-2 col-sm-12 col-md-1 col-lg-1 icon';
	const classBotao = className ? `${className} ${classTipoBotao}` : `${classTipoBotao}`;

	const setPesquisando = () => dispatch({ type: ActionsKeys.TOGGLE_PESQUISANDO, isPesquisando: true });

	function renderResultados(e) {
		e.preventDefault();
		
		// if (idEstilo === ID_ESTILO_BARRA_BUSCA_INTERNA) {
			setPesquisando();
		// } else if (idEstilo === ID_ESTILO_BARRA_BUSCA_EXTERNA) {
			// window.location = `${URL_TELA_RESULTADO}?filtro=${filtroBusca}&termo=${termoBusca.split(' ').join('%20')}`;
		// }
	}

	const [isBotaoDisabled, setBotaoDisabled] = useState();

	useEffect(() => {
		setBotaoDisabled(termoBusca.trim().length < 3);
	}, [termoBusca]);

	return (
		<button className={classBotao} id={id} onClick={renderResultados} disabled={isBotaoDisabled} data-tooltip-btn-buscar='Insira 3 dígitos ou mais'>
			{IcnsAPI['lupa-busca']}
		</button>
	);
}