import React from 'react';
import Apresentacao from "./Apresentacao";
import Fotos from "./Fotos";
import Servicos from "./Servicos";
import Conveniencias from "./Conveniencias";

export default function DadosCredenciado(props) {
    return (
        <section className="row col-12 col-sm-12 col-md-12 col-lg-8">
            <Apresentacao texto={props.apresentacao}/>

            <Fotos imagens={props.fotos}/>

            <Servicos servicos={props.servicos}/>

            <Conveniencias conveniencias={props.conveniencias}/>
        </section>
    );
}