import React from 'react';

function Endereco(props) {
    const isEndereco = props.endereco && props.endereco !== '';

    if (!isEndereco) return null;
    
    return (
        <p className={props.class, `txt-principal`}>
            {props.endereco}
            {props.numero && `, ${props.numero}`}
            {props.complemento && ` / ${props.complemento}`}
        </p>
    );
}

function CidadeUF(props) {
    const isCidade = props.cidade && props.cidade !== '';
    const isEstado = props.estado && props.estado !== '';

    if (!isCidade || !isEstado) return null;
    
    return (
        <p className={props.className}>
            {props.cidade}/{props.estado}
        </p>
    );
}

function CEP(props) {
    const isCEP = props.cep && props.cep !== '';

    if (!isCEP) return null;

    return (
        <p className={props.class}>
            CEP {props.cep}
        </p>
    );
}

export {
    Endereco,
    CidadeUF, 
    CEP
}