import React, { useEffect, useState } from 'react';
import { _fetchPorCodigo, _fetchChaveMapsAPI } from '../util/fetch-api';
import Header from '../common/Header';
import Cabecalho from '../perfil/Cabecalho';
import Conteudo from '../perfil/Conteudo';
import { useSelector } from 'react-redux';

export default function PerfilCredenciado(props) {
	const { credenciadoPerfil } = useSelector(state => state);

	const [init, setInit] = useState(false);
	const [credenciado, setCredenciado] = useState();

	useEffect(() => {
		const idCredenciado = props.match.params.id || 0;

		if (idCredenciado) { 
			_fetchPorCodigo(idCredenciado)
			.then(retorno => setCredenciado(retorno))
			.then(() => setInit(true));
		} else {
			setCredenciado(credenciadoPerfil);
			setInit(true);
		}

	}, [credenciadoPerfil]);

	return (
		<React.Fragment>
			<Header/>
			
			<div id="div-perfil-empresa">
				{init && <Cabecalho credenciado={credenciado}/>}

				{init && <Conteudo credenciado={credenciado}/>}
			</div>
		</React.Fragment>
	);
}