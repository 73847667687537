import React from 'react';
import IcnsAPI from '../../util/iconesAPI';
import { Endereco, CidadeUF, CEP } from '../util-endereco';

export default function EnderecoCredenciado(props) {
    const isEndereco = props.endereco.endereco && props.endereco.endereco !== '';
    const isCidadeUF = props.cidade && props.cidade !== '' && props.estado && props.estado !== '';
    const isCEP = props.endereco.cep && props.endereco.cep !== '';
    const isDadosEndereco = isEndereco || isCidadeUF || isCEP;
    
    if (!isDadosEndereco) return null;

    return (
        <div className="endereco-empresa row col-12">
            <span>{IcnsAPI['localizacao']}</span>

            <span className="conjunto-dados col-lg-10 col-10">
                <Endereco
                    endereco={props.endereco.endereco}
                    numero={props.endereco.numero}
                    complemento={props.endereco.complemento}
                    className={'txt-principal'}
                />

                <CidadeUF
                    cidade={props.endereco.cidade}
                    estado={props.endereco.estado}
                    className='txt-secundario'
                />

                <CEP
                    cep={props.endereco.cep}
                    className='txt-secundario'
                />
            </span>
        </div>
    );
}