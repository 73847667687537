import React from 'react';
import IcnsAPI from '../util/iconesAPI';
import FiltroDistancia from './FiltroDistancia';
import FiltroOpcoes from './FiltroOpcoes';
import { useSelector, useDispatch } from 'react-redux';
import ActionsKeys from '../store/actionsKeys';

export default function Filtros() {
	const dispatch = useDispatch();
	const {
		conveniencias,
		convenienciasSelecionadas,
		resultadosPorConveniencia,
		servicos,
		servicosSelecionados,
		resultadosPorServico,
		isFiltrosExibidos,
	} = useSelector(state => state);

	const toggleConveniencia = e => dispatch({ type: ActionsKeys.TOGGLE_CONVENIENCIA, idConveniencia: e.target.value });
    const toggleServico = e => dispatch({ type: ActionsKeys.TOGGLE_SERVICO, idServico: e.target.value });
    const toggleFiltros = () => dispatch({ type: ActionsKeys.TOGGLE_FILTROS, exibir: !isFiltrosExibidos });

    return (
        <>
            <section className={`section-sidenav filtros-busca ${isFiltrosExibidos ? '' : 'mostra-filtros'}`}>
                <div className="titulo-filtros">
                    <span>Filtros</span>

					<span className="acao-fechar" onClick={toggleFiltros}>
						{IcnsAPI['fechar']}
					</span>
				</div>

                <FiltroDistancia/>

                <FiltroOpcoes
                    nome='Conveniências'
                    id='div-filtro-conveniencias'
                    classe='filtro-conveniencia'
                    tipoFiltro='conveniencias'
                    expandir='Mostrar todas as conveniências'
                    lista={conveniencias}
                    selecao={convenienciasSelecionadas}
                    onClickOpcao={toggleConveniencia}
                    quantidadePorItem={resultadosPorConveniencia}
                />

                <FiltroOpcoes
                    nome='Serviços'
                    id='div-filtro-servicos'
                    classe='filtro-servico'
                    tipoFiltro='servicos'
                    expandir='Mostrar todos os serviços'
                    lista={servicos}
                    selecao={servicosSelecionados}
                    onClickOpcao={toggleServico}
                    quantidadePorItem={resultadosPorServico}
                />
            </section>

            <button className={`btn-filtro col-2 center icon ${isFiltrosExibidos ? '' : 'margin-left'}`} onClick={toggleFiltros}>
                {isFiltrosExibidos ? IcnsAPI['voltar'] : IcnsAPI['filtro']}
            </button>
        </>
    );
}