import React from 'react';
import IcnsAPI from '../../util/iconesAPI';

export default function TelefonesCredenciado(props) {
    const isTelefone = props.telefone && props.telefone !== '';
    const isTelefone2 = props.telefone2 && props.telefone2 !== '';
    const isCelular = props.celular && props.celular !== '';
    const isTelefones = isTelefone || isTelefone2 || isCelular;

    if (!isTelefones) return null;

    return (
        <div className="telefone-empresa row col-12">
            <span>{IcnsAPI['telefone']}</span>

            <span className="conjunto-dados col-lg-10 col-10">
                <p className="txt-principal">
                    {isTelefone && <span>{props.telefone}</span>}

                    {isTelefone2 && <span>{props.telefone2}</span>}

                    {isCelular && <span>{props.telefoneCelular}</span>}
                </p>
            </span>
        </div>
    );
}