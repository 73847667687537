function isHiddenPelosFiltros(codigosFiltros, propsCredenciado) {
    let hidden = !propsCredenciado;
    
    if (codigosFiltros && propsCredenciado) {
        propsCredenciado = Object.keys(propsCredenciado);
        
        if (propsCredenciado) {
            hidden = propsCredenciado.filter(prop => codigosFiltros.includes(prop)).length !== codigosFiltros.length;     
        }
    }

    return hidden;
}

const _aplicaFiltrosSelecionados = (credenciados, convenienciasSelecionadas, servicosSelecionados) => credenciados.map(credenciado => {
	let hiddenConveniencia = isHiddenPelosFiltros(convenienciasSelecionadas, credenciado.diferencialMap);
	let hiddenServico = isHiddenPelosFiltros(servicosSelecionados, credenciado.servicoMap);

	credenciado.hidden = hiddenConveniencia || hiddenServico;
	return credenciado;
});

export {
    _aplicaFiltrosSelecionados
}