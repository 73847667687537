import React from 'react';
import { IcnSite } from "../../common/icones";

export default function SiteCredenciado(props) {
    const isSite = props.link && props.link !== '';

    if (!isSite) return null;

    return (
        <div className="site-empresa row col-12">
            <span>{IcnSite}</span>

            <span className="conjunto-dados col-lg-10 col-10" data-tooltip-big={props.link}>
                <p className="txt-principal truncate">{props.link}</p>
            </span>
        </div>
    );
}