import React from 'react';
import BtnVoltarBusca from "./BtnVoltarBusca";
import RedesSociais from './RedesSociais';
import { IcnEmpresaPadrao } from '../common/icones';

let getPath = () => document.getElementById('path').value;

function LogoCredenciado({ logo }) {
    return (
        <div className="perfil-logo-empresa center">
            {logo && <img alt="Logo da empresa" src={logo}/>}
            {!logo && IcnEmpresaPadrao}
        </div>
    );
}

function NomeCredenciado({ nome, className }) {
    return (
        <div className="nome-empresa" data-tooltip-bottom-left={nome}>
            <span className={`truncate ${className ? className : ''}`}>{nome}</span>
        </div>
    );
}

function CNPJCredenciado({ cnpj }) {
    if (!cnpj || cnpj === '') return null;

    return (
        <div className="cnpj-empresa">{cnpj}</div>
    );
}

function DadosCredenciado({ credenciado }) {
    const isNomeFantasia = credenciado.nomeFantasia && credenciado.nomeFantasia !== '';
    const isRazaoSocial = credenciado.razaoSocial && credenciado.razaoSocial !== '';
    
    return (
        <div className="perfil-dados-empresa">
            {isNomeFantasia && <NomeCredenciado nome={credenciado.nomeFantasia}/>}

            {!isNomeFantasia && <NomeCredenciado nome={credenciado.razaoSocial}/>}

            {isNomeFantasia && isRazaoSocial && <NomeCredenciado nome={credenciado.razaoSocial} className="detalhe-nome"/>}

            <CNPJCredenciado cnpj={credenciado.cnpj}/>

            <RedesSociais redesSociais={credenciado.redesSociais}/>
        </div>
    );
}

export default function Cabecalho({ credenciado }) {
    return (
        <div id="div-header-perfil" className="row col-12">
            <div className="large-wrapper col-12 conteudo-header-perfil">
                <BtnVoltarBusca/>

                <LogoCredenciado logo={credenciado.logo}/>

                <DadosCredenciado credenciado={credenciado}/>
            </div>
        </div>
    );
}