import React from 'react';
import IcnsAPI from '../util/iconesAPI';

const titulo = "Serviços";

function Servico(props) {
    return (
        <p className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 row">
            {IcnsAPI["padrao"]}

            <span className="col-11">{props.nome}</span>
        </p>
    );
}

export default function Servicos(props) {
    if (!props.servicos || !Object.values(props.servicos).length) return null;
    
    const itensServicos = Object.values(props.servicos).map(servico => <Servico nome={servico.nome}/>);
    
    return (
        <div className="row col-12 conteudo-central">
            <span className="txt-principal col-12">{titulo}</span>
            
            <span className="row col-12">
                {itensServicos}
            </span>
        </div>
    );
}