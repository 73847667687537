import React from 'react';

export default function Header() {
	const titulo = 'Rede Credenciada SOCNET';

	return (
		<div className="titulo-rede-credenciada">
			{titulo}
		</div>
	);
}
