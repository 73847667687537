import React, { useEffect, useState, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { _fetchChaveMapsAPI, _fetchFiltrosBusca } from './util/fetch-api';
import BuscaCredenciado from './pages/BuscaCredenciados';
import PerfilCredenciado from './pages/PerfilCredenciado';
import BarraBusca from './components/BarraBusca';
import ActionsKeys from './store/actionsKeys';

function Loading() {
	return (
		<div id="div-carregando-operacao">
			<div id="div-infos-carregando-operacao">
				<div className="loader"></div>
			</div>
		</div>
	);
}

export default function App() {
	const dispatch = useDispatch();

	const [init, setInit] = useState(false);

	const setKeyMaps = keyMaps => dispatch({ type: ActionsKeys.SET_KEY_MAPS, keyMaps });
	const setConveniencias = conveniencias => dispatch({ type: ActionsKeys.SET_CONVENIENCIAS, conveniencias });
	const setServicos = servicos => dispatch({ type: ActionsKeys.SET_SERVICOS, servicos });

	useEffect(() => {
		Promise.all([_fetchChaveMapsAPI(), _fetchFiltrosBusca()])
		.then(retornos => {
			setKeyMaps(retornos[0]);
			setConveniencias(retornos[1].conveniencias);
			setServicos(retornos[1].servicos);
		})
		.then(() => setInit(true));
	}, []);

	return (
		<BrowserRouter>
			<Switch>
				{!init && <Loading />}
				{init && 
					<Route
						path='/'
						exact={true}
						component={BuscaCredenciado}
					/>
				}

				{init && <Route
					path='/busca/:filtros'
					exact={true}
					component={BuscaCredenciado}
				/>}

				{init && <Route
					path='/perfil'
					exact={true}
					component={PerfilCredenciado}
				/>}

				{init && <Route
					path='/perfil/:id'
					exact={true}
					component={PerfilCredenciado}
				/>}

				<Route
					path='/barra'
					exact={true}
					component={BarraBusca}
				/>
			</Switch>
		</BrowserRouter>
	);
}