import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ActionsKeys from '../store/actionsKeys';

export default function OrdenacaoResultados() {
    const dispatch = useDispatch();

    const { 
        ordenacaoSelecionada,
        filtroBusca
    } = useSelector(state => state);

    const setOrdenacao = ordenacao => dispatch({ type: ActionsKeys.SET_ORDENACAO, ordenacao });

    const trocaTipoOrdenacao = e => setOrdenacao(e.target.value);
    
    return (
        <select id="tipo-ordenacao-resultados" className="col-sm-12 col-md-3" onChange={trocaTipoOrdenacao} value={ordenacaoSelecionada}>
            <option value="0">Alfabética A-Z</option>
            <option value="1">Alfabética Z-A</option>
            {filtroBusca === 0 && <option value="2">Mais próximos</option>}
        </select>
    );
}