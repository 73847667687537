import React, { useEffect } from 'react';
import LoadingOperacao from '../components/LoadingOperacao';
import Header from '../common/Header';
import Filtros from '../busca/Filtros';
import ConteudoBusca from '../busca/ConteudoBusca';
import { _fetchResultadosBusca } from '../util/fetch-api';
import { useSelector, useDispatch } from 'react-redux';
import ActionsKeys from '../store/actionsKeys';

export default function BuscaCredenciado() {
	const dispatch = useDispatch();

	const {
		keyMaps,
		isCarregando,
		isPesquisando,
		filtroBusca,
		termoBusca,
		distanciaSelecionada,
	} = useSelector(state => state);

	const setPesquisando = isPesquisando => dispatch({ type: ActionsKeys.TOGGLE_PESQUISANDO, isPesquisando });
	const setResultadosBusca = credenciados => dispatch({ type: ActionsKeys.SET_RESULTADOS, credenciados });
	const setLatitudeBusca = lat => dispatch({ type: ActionsKeys.SET_LATITUDE, lat });
	const setLongitudeBusca = lng => dispatch({ type: ActionsKeys.SET_LONGITUDE, lng });

    function buscaCredenciados() {
		if (!isPesquisando) return;
		if (termoBusca) {
			_fetchResultadosBusca(keyMaps, filtroBusca, termoBusca, distanciaSelecionada)
			.then(jsonFetch => {
				setResultadosBusca(jsonFetch.credenciados);
				setLatitudeBusca(jsonFetch.lat);
				setLongitudeBusca(jsonFetch.lng);
			})
			.then(() => {
				setPesquisando(false);
			});
		} else {
			setPesquisando(false);
		}
    }

    useEffect(buscaCredenciados, [isPesquisando]);

    return (
        <div className="pg-busca-socnet">
            {isCarregando && <LoadingOperacao/>}

            <Header/>

			<Filtros/>

			<ConteudoBusca/>
        </div>
    );
};