import React from 'react';
import { useSelector } from 'react-redux';
import Topo from './Topo';
import Resultados from './Resultados';
import TopoResultados from './TopoResultados';

export default function ConteudoBusca() {
    const { isFiltrosExibidos } = useSelector(state => state);
    
    return (
       <section className={`section-content resultado-busca ${isFiltrosExibidos ? '' : 'mostra-filtros'}`}>
			<Topo/>

			<TopoResultados/>

			<Resultados/>
        </section>
    )
}