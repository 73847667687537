import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import ActionsKeys from '../store/actionsKeys';
import { useDispatch } from 'react-redux';

export default function LinkPerfil({ credenciado, className }) {
	const dispatch = useDispatch();

	const setCredenciadoPerfil = credenciado => dispatch({ type: ActionsKeys.SET_CREDENCIADO_PERFIL, credenciado });

	return (
		<Link to='/perfil'>
			<button type="button" data-btn='generico' onClick={() => setCredenciadoPerfil(credenciado)} className={className}>
				Saiba mais
			</button>
		</Link>
	);
}