import React from 'react';
import { IcnFacebook, IcnInstagram, IcnLinkedin, IcnTwitter } from "../common/icones";

let redesSociais = [];

function LinkRedeSocial(props) {
    return (
        <a href={props.url} target="_blank" rel="noopener noreferrer" className={props.class}>{props.icone}</a>
    );
}

export default function RedesSociais(props) {
    if (props.redesSociais) redesSociais = JSON.parse(props.redesSociais);

    const isFacebook = redesSociais.facebook && redesSociais.facebook !== '' && redesSociais.facebook.pathname;
    const isInstagram = redesSociais.instagram && redesSociais.instagram !== '' && redesSociais.instagram.pathname;
    const isLinkedin = redesSociais.linkedin && redesSociais.linkedin !== '' && redesSociais.linkedin.pathname;
    const isTwitter = redesSociais.twitter && redesSociais.twitter !== '' && redesSociais.twitter.pathname;

    return (
        <div className="redes-sociais-empresa">
            {isFacebook && <LinkRedeSocial url={redesSociais.facebook} className="lnk-facebook-empresa" icone={IcnFacebook}/>}

            {isInstagram && <LinkRedeSocial url={redesSociais.instagram} className="lnk-instagram-empresa" icone={IcnInstagram}/>}

            {isLinkedin && <LinkRedeSocial url={redesSociais.linkedin} className="lnk-linkedin-empresa" icone={IcnLinkedin}/>}

            {isTwitter && <LinkRedeSocial url={redesSociais.twitter} className="lnk-twitter-empresa" icone={IcnTwitter}/>}
        </div>
    );
}