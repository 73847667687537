import React, { Fragment } from 'react';
import ItemRedePropria from "./ItemRedePropria";
import ItemRedeCredenciada from "./ItemRedeCredenciada";

let filiais;
let estados;

export default function EnderecosRede(props) {
    filiais = null;
    estados = null;

    const sede = 
        <ItemRedePropria
            isFilial={props.credenciado.filial}
            enderecoJSON={props.credenciado.enderecoVo}
            email={props.credenciado.email}
            telefone={props.credenciado.telefone}
            horaInicial={props.credenciado.horaInicial}
            horaFinal={props.credenciado.horaFinal}
        />
    ;

    if (props.filiais) {
        filiais = props.filiais.map(item => 
            <ItemRedePropria
                isFilial={item.filial}
                enderecoJSON={item.enderecoVo}
                email={item.email}
                telefone={item.telefone}
                horaInicial={item.horaInicial}
                horaFinal={item.horaFinal}
            />
        );
    }

    if (props.estados) {
        estados = props.estados.map(estado => <ItemRedeCredenciada nome={estado.nome}/>);
    }

    return (
        <ul id="lista-enderecos-empresa" className={`seamless`}>
            {props.state === '0' && 
                <Fragment>
                    {sede}
                    {filiais}
                </Fragment>
            }

            {props.state === '1' && estados}
        </ul>
    );
}