import React, { Fragment } from 'react';
import EnderecoCredenciado from "./Endereco";
import SiteCredenciado from "./Site";
import EmailCredenciado from "./Email";
import TelefonesCredenciado from "./Telefones";
import HorarioAtendimentoCredenciado from "./HorarioAtendimento";

export default function Informacoes(props) {
    return (
        <Fragment>
            {props.endereco && <EnderecoCredenciado endereco={props.endereco}/>}

            <SiteCredenciado link={props.site}/>

            <EmailCredenciado email={props.email}/>

            <TelefonesCredenciado telefone={props.telefone} telefone2={props.telefone2} celular={props.telefoneCelular}/>

            <HorarioAtendimentoCredenciado inicio={props.horaInicial} fim={props.horaFinal}/>
        </Fragment>
    );
}