import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ActionsKeys from '../store/actionsKeys';

const getDivClicavelComponente = () => document.getElementById('lnk-mostra-opcoes');

const valorMinimo = 10;
const valorMaximo = 100;

function BotaoDistancia(props) {
	function onClick(e) {
		const opcao = e.target.dataset.valor;

		let btnAtual = document.getElementById(`btn-distancia-${opcao}`);
		btnAtual.closest('button.active') && btnAtual.closest('button.active').classList.remove('active');
		btnAtual.classList.add('active');

		props.setOpcoesVisiveis(false);
		props.onClick(opcao);
	}

	return (
		<button
			id={`btn-distancia-${props.opcao}`}
			className={`${props.opcao === props.distancia ? 'active' : ''} col-5`}
			data-valor={props.opcao}
			onClick={onClick}
		>
			{props.opcao} km
		</button>
	);
}

export default function FiltroDistancia() {
	const dispatch = useDispatch();

	const {
		filtroBusca,
		distanciaSelecionada
	} = useSelector(state => state);

	const [opcoesVisiveis, setOpcoesVisiveis] = useState(false);
	const refOpcoes = useRef(null);

	const setDistancia = distancia => dispatch({ type: ActionsKeys.SET_DISTANCIA, distancia });
	const setPesquisando = () => dispatch({ type: ActionsKeys.TOGGLE_PESQUISANDO, isPesquisando: true });

	function selecionaDistancia(distancia) {
		setDistancia(distancia);
		setPesquisando();
	}

    let valoresOpcoes = [];

	for (let indiceLoop = valorMinimo; indiceLoop <= valorMaximo; indiceLoop += 10) {
		valoresOpcoes.push(indiceLoop);
	}

	const botoesDistancia = valoresOpcoes.map((opcao, index) => {
		return (
            <BotaoDistancia
                opcao={opcao}
                distancia={distanciaSelecionada}
				onClick={selecionaDistancia}
				setOpcoesVisiveis={setOpcoesVisiveis}
				key={index}
            />
        );
	});

	function trataVisibilidadeOpcoes() {
		if (getDivClicavelComponente().classList.contains('disabled')) return;

		opcoesVisiveis ? setOpcoesVisiveis(false) : setOpcoesVisiveis(true);
	}

	function fechaOpcoesAoClicarForaDoComponente() {
		document.addEventListener('mousedown', (e) => {
			const elemento = e.target;
			const isDivComponente = elemento === getDivClicavelComponente();
			const isEscondeOpcoes = opcoesVisiveis && !isDivComponente && refOpcoes.current && !refOpcoes.current.contains(elemento) && !getDivClicavelComponente().contains(elemento);

			if (isEscondeOpcoes) setOpcoesVisiveis(false);
		});
	}

	useEffect(fechaOpcoesAoClicarForaDoComponente);

	return (
		<div className="componente-filtro">
			<span className="tipo-filtro">Área de pesquisa</span><span> (Raio)</span>
			
			<div className={`filtro-km ${filtroBusca === 1 ? 'disabled':''}`} id="lnk-mostra-opcoes" onClick={trataVisibilidadeOpcoes}>
				<input type="hidden" id="ipt-hdn-distancia-selecionada" value={distanciaSelecionada || ''}/>

				<span>Em até {distanciaSelecionada} km</span>

				<span className="alteracao">Alterar</span>
			</div>

			{opcoesVisiveis && 
				<div className="row filtro-km div-opcoes justify-content-around" ref={refOpcoes}>
					{botoesDistancia}
				</div>
			}
		</div>
	);
}