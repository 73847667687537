import React from 'react';
import LinkPerfil from '../common/LinkPerfil';
import { useSelector } from 'react-redux';

export default function CustomInfoWindow() {
    const { infoWindow } = useSelector(state => state);

    return(
        <div className="infowindow-maps">
            <b className="truncate">{infoWindow.nome}</b>
            <p>{infoWindow.telefone}</p>
            <p>{infoWindow.endereco}</p>

            <LinkPerfil
                className={`center btn-alinhado btn-estreito`}
                credenciado={infoWindow.credenciado}
            />
        </div>
    )
}