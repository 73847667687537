import React from 'react';
const isMobile = window.innerWidth <= 600;
const classMobile = isMobile ? '' : 'order-last';

function Imagem(props) {
    return (
        <div className="col-lg-12 col-12 item">
            <img alt="Foto do credenciado" src={props.imagem}></img>
        </div>
    );
}

export default function Fotos(props) {
    if (!props.imagens || props.imagens === '') return null;
    
    const imagens = props.imagens.map(imagem => <Imagem imagem={imagem}/>);

    return (
        <div className={`col-12 responsive-nowrap conteudo-central imagens-empresa ${classMobile}`}>
            <div className="row col-12 infinite-scroll">
                {imagens}
            </div>
        </div>
    );
}