import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ActionsKeys from '../store/actionsKeys';
import { IcnMapa } from '../common/icones';

export default function Marcador({ credenciado }) {
    const dispatch = useDispatch();
    const setCodigoCardAtivo = codigo => dispatch({ type: ActionsKeys.SET_CODIGO_CARD_ATIVO, codigo });
    const setCentroMapa = latlng => dispatch({ type: ActionsKeys.SET_CENTRO_MAPA, latlng });
    const setInfoWindowVisivel = visivel => dispatch({ type: ActionsKeys.TOGGLE_INFO_WINDOW, visivel });
    const setInfoWindow = infoWindow => dispatch({ type: ActionsKeys.SET_INFO_WINDOW, infoWindow });

    const { codigoCardAtivo } = useSelector(state => state);
    const refMarcador = useRef(null);

    useEffect(() => {
        if (refMarcador.current.classList.contains('active')) {
            
            let lat = parseFloat(credenciado.enderecoVo.latitude);
            let long = parseFloat(credenciado.enderecoVo.longitude);

            const enderecoVo = credenciado.enderecoVo;
            const validacaoNumero = enderecoVo.numero ? `, ${enderecoVo.numero}` : '';
            const validacaoCidade = enderecoVo.cidade ? `, ${enderecoVo.cidade}` : '';
            const validacaoEstado = enderecoVo.estado ? `/${enderecoVo.estado}` : '';
            const validacaoCep = enderecoVo.cep ? ` - ${enderecoVo.cep}` : ''
            const endereco = enderecoVo.endereco ? `${enderecoVo.endereco}${validacaoNumero}${validacaoCidade}${validacaoEstado}${validacaoCep}` : '';

            setCentroMapa({lat:lat, lng:long});
            setInfoWindow({
                codigo: credenciado.codigo,
                nome: credenciado.nomeFantasia ? credenciado.nomeFantasia : credenciado.razaoSocial,
                telefone: credenciado.telefone ? credenciado.telefone : '',
                endereco: endereco,
                credenciado: credenciado,
            })

            setInfoWindowVisivel(true);
            
        }
    }, [codigoCardAtivo])

    return(
        <div 
            className={`icn-mapa ${(codigoCardAtivo == credenciado.codigo) ? 'active' : ''}`} 
            id={`div-marcador-${credenciado.codigo}`} 
            onClick={() => setCodigoCardAtivo(credenciado.codigo)} 
            key={credenciado.codigo} 
            ref={refMarcador}
        >
            {IcnMapa}
        </div>
    )
}