import React, { useState, useEffect } from 'react';
import Mapa from './sidebar/Mapa';
import Informacoes from './sidebar/Informacoes';
import Filiais from './sidebar/Filiais';
import Estados from './sidebar/Estados';
import ModalAreasAtendidas from './sidebar/ModalAreasAtendidas';

const isMobile = window.innerWidth <= 600;

export default function Sidebar({ credenciado }) {
    const [modal, abreModal] = useState(false);
    const [tipoRede, setTipoRede] = useState();
    const [isLatLng, setIsLatLng] = useState();
    const [isRedePropria, setIsRedePropria] = useState();
    const [isRedeCredenciada, setIsRedeCredenciada] = useState();

    useEffect(() => {
        setIsLatLng(credenciado.enderecoVo && credenciado.enderecoVo.latitude && credenciado.enderecoVo.longitude);
        setIsRedePropria(credenciado.filialList && credenciado.filialList.length > 0);
        setIsRedeCredenciada(credenciado.estadoAtendidoList && credenciado.estadoAtendidoList.length > 0);
    }, []);

    function selectRedePropria() {
        abreModal(true);
        setTipoRede('0');
    }

    function selectRedeCredenciada() {
        abreModal(true);
        setTipoRede('1');
    }

    return (
        <section className={`col-12 col-sm-12 col-md-12 col-lg-4 ${isMobile ? 'order-first' : ''}`}>
            <div id="sidebar-perfil">
                {isLatLng && 
                    <Mapa
                        mobile={isMobile}
                        latitude={credenciado.enderecoVo.latitude || 0}
                        longitude={credenciado.enderecoVo.longitude || 0}
                    />
                }

                <Informacoes
                    endereco={credenciado.enderecoVo}
                    site={credenciado.site}
                    email={credenciado.email}
                    telefone={credenciado.telefone}
                    telefone2={credenciado.telefone2}
                    telefoneCelular={credenciado.telefoneCelular}
                    horaInicial={credenciado.horaInicial}
                    horaFinal={credenciado.horaFinal}
                />
                
                {isRedePropria && 
                    <Filiais 
                        filiais={credenciado.filialList} 
                        onClick={selectRedePropria}
                    />
                }

                {isRedeCredenciada && 
                    <Estados
                        estados={credenciado.estadoAtendidoList}
                        onClick={selectRedeCredenciada}
                    />
                }

                {modal && 
                    <ModalAreasAtendidas
                        onClick={() => abreModal(false)}
                        credenciado={credenciado}
                        redePropria={isRedePropria}
                        redeCredenciada={isRedeCredenciada}
                        state={tipoRede}
                        alteracaoEstado={setTipoRede}
                    />
                }
            </div>
        </section>
    );
}