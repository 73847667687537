import React from 'react';
function filtraEmpresas(e) {
    let itensListaEmpresas = document.querySelectorAll('#lista-enderecos-empresa li');

    itensListaEmpresas.forEach((itemEmpresa) => {
        let conteudoItemEmpresa = itemEmpresa.textContent.toLowerCase();
        let valorInputFiltro = e.target.value.toLowerCase();

        if ((valorInputFiltro === '') || (conteudoItemEmpresa.includes(valorInputFiltro))) itemEmpresa.removeAttribute('hidden');
        if (!conteudoItemEmpresa.includes(valorInputFiltro)) itemEmpresa.setAttribute('hidden', true);
    });
}

export default function FiltroLocalRede() {
    return (
        <input
            type="text"
            placeholder="Buscar local"
            autoComplete="off"
            id="ipt-busca-filiais-estados"
            name="ipt-busca-filiais-estados"
            onChange={filtraEmpresas}
        />
    );
}