import React, { useState } from 'react';
import Geocode from 'react-geocode';
import ActionsKeys from '../store/actionsKeys';
import { useDispatch } from 'react-redux';

export default function BotaoGeolocalizacao({ classeBotao, keyMaps }) {
	const dispatch = useDispatch();

	const [acessoGeolocalizacao] = useState(navigator.geolocation);

	const aviso = "O seu navegador não tem suporte a geolocalização.";
	const classePadrao = 'btn-limpo-estilizado col-2 col-sm-12 col-md-2 col-lg-2';
	const classBotao = classeBotao ? `${classePadrao} ${classeBotao}` : classePadrao;

	const setTermoBusca = termo => dispatch({ type: ActionsKeys.SET_TERMO_BUSCA, termo});
	const setPesquisando = () => dispatch({ type: ActionsKeys.TOGGLE_PESQUISANDO, isPesquisando: true });

	function buscaEnderecoUsuario(e) {
		acessoGeolocalizacao.getCurrentPosition((localizacao) => {
			let latlng = {
				lat: parseFloat(localizacao.coords.latitude),
				lng: parseFloat(localizacao.coords.longitude)
			};

			Geocode.setApiKey(keyMaps);
			Geocode.fromLatLng(latlng.lat, latlng.lng)
			.then(
				response => {
					setTermoBusca(response.results[0].formatted_address);
					setPesquisando();
				}
			);
		});
	}

	return (
		<button 
			type="button" 
			className={classBotao} 
			onClick={buscaEnderecoUsuario} 
			disabled={!acessoGeolocalizacao}
			data-tooltip-bottom-left={aviso}>
			Próximos a mim
		</button>
	);
}