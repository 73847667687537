import React from 'react';

export default function LoadingOperacao() {
	return (
		<div id="div-carregando-operacao">
			<div id="div-infos-carregando-operacao">
				<span>Buscando prestadores</span>

				<div className="loader"></div>
			</div>
		</div>
	);
}