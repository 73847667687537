import React from 'react';
import IcnsAPI from '../util/iconesAPI';
import { Link } from 'react-router-dom';

const textoBtnVoltar = "Buscar prestadores";

export default function BtnVoltarBusca() {
	return (
		<div className="retornar">
			<Link to='/'>
				{IcnsAPI['seta-voltar']}
				{textoBtnVoltar}
			</Link>
		</div>
	);
}