import React from 'react';
import IcnsAPI from '../util/iconesAPI';
import BarraBusca from '../components/BarraBusca';
import TogglePadrao from '../components/Toggle';
import { useSelector, useDispatch } from 'react-redux';
import ActionsKeys from '../store/actionsKeys';
import ChipsFiltros from './ChipsFiltros';

export default function Topo() {
    const dispatch = useDispatch();
    const { 
        filtroBusca,
        tipoVisualizacao 
    } = useSelector(state => state);

    const setTipoVisualizacao = tipo => dispatch({ type: ActionsKeys.SET_TIPO_VISUALIZACAO, tipo });

    return (
		<div className="row topo-fixo-pesquisa">
			<BarraBusca estilo="principal"/>

			<div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 div-toggle">
				<TogglePadrao
					id="tgl-tipo-visualizacao"
					tipo="padrao"
					icone1={IcnsAPI['localizacao']}
					opcao1="Mapa"
					icone2={IcnsAPI['grid']}
					opcao2="Lista"
					alteracaoEstado={setTipoVisualizacao}
					stateAtual={filtroBusca}
					defaultState={tipoVisualizacao}
				/>
			</div>

			<ChipsFiltros/>
		</div>
    );
}