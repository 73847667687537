function getSiglaEstadoDaLocalizacao(dadosLocalizacao) {
	let estado = '';

	dadosLocalizacao.address_components.forEach(componenteEndereco => {
		if ( componenteEndereco.types.indexOf('administrative_area_level_1') !== -1 ) {
			estado = componenteEndereco.short_name;
		}
	});

	return estado;
};

function getDadosLocalizacaoPorEndereco(endereco, chave) {	
	let URL_API_GOOGLE_DADOS_LOCALIZACAO = "https://maps.googleapis.com/maps/api/geocode/json";
	let url = `${URL_API_GOOGLE_DADOS_LOCALIZACAO}?address=${endereco}&key=${chave}`;

	return fetch(url, {
		method: 'POST'
	})
	.then(res => {
		if (res.ok) return res.json();
	})
	.then(json => json.results);
};

async function _getDadosEndereco(endereco, chave) {
	let resultados = await getDadosLocalizacaoPorEndereco(endereco, chave);

	if (!resultados[0]) return null;

	resultados = resultados[0];
	let estadoLocalizacao = getSiglaEstadoDaLocalizacao(resultados);

	return {
		latitude : resultados.geometry.location.lat,
		longitude : resultados.geometry.location.lng,
		tipo_endereco : resultados.types[0],
		estado : estadoLocalizacao
	};
};

export {
	_getDadosEndereco
};