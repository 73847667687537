import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ActionsKeys from '../store/actionsKeys';
import IcnsAPI from '../util/iconesAPI';

export default function ChipsFiltros() {
	const dispatch = useDispatch();

	const {
		conveniencias,
		convenienciasSelecionadas,
		distanciaSelecionada,
		resultadosBusca,
		servicos,
		servicosSelecionados,
		termoBusca,
	} = useSelector(state => state);

	const [chipTermoBusca, setChipTermoBusca] = useState();
	const [chipsConveniencias, setChipsConveniencias] = useState();
	const [chipsServicos, setChipsServicos] = useState();

	const getItem = (lista, codigo) => lista.filter(item => item.codigo === codigo)[0] || null;

	const constroiChipsFiltros = (selecao, filtro) => {
		return selecao.map(codigo => {
			const item = getItem(filtro, codigo);

			return (
				<span className="chip" key={codigo}>
					{item.nome}

					<span className="excluir" onClick={() => setFiltros(filtro, codigo)}>
						{IcnsAPI['fechar']}
					</span>
				</span>
			);
		});
	}

	const setFiltros = ( filtro, codigo ) => {
		if (filtro === conveniencias) {
			dispatch({ type: ActionsKeys.TOGGLE_CONVENIENCIA, idConveniencia: codigo });
		} else {
			dispatch({ type: ActionsKeys.TOGGLE_SERVICO, idServico: codigo });
		}
	};

	useEffect(() => {
		const isNovaBusca = termoBusca !== chipTermoBusca;
		setChipTermoBusca(isNovaBusca ? termoBusca : chipTermoBusca);
	}, [resultadosBusca]);

	useEffect(() => {
		setChipsConveniencias(constroiChipsFiltros(convenienciasSelecionadas, conveniencias));
	}, [convenienciasSelecionadas]);

	useEffect(() => {
		setChipsServicos(constroiChipsFiltros(servicosSelecionados, servicos));
	}, [servicosSelecionados]);

	return (
		<div className="col-12" data-chips>
			{chipTermoBusca && <span className="chip">
				{chipTermoBusca}
			</span>}

			<span className="chip">
				Em até {distanciaSelecionada}km
			</span>

			{chipsConveniencias}

			{chipsServicos}
		</div>
	);
}