import React, { useEffect, useState } from 'react';
import IcnsAPI from '../util/iconesAPI';
import { GoogleApiWrapper } from 'google-maps-react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { useSelector, useDispatch } from 'react-redux';
import ActionsKeys from '../store/actionsKeys';

const searchOptions = { componentRestrictions: {country: "br"} };

function InputBarraBusca({ id, classInput }) {
    const dispatch = useDispatch();
    const {
        filtroBusca,
        termoBusca
    } = useSelector(state => state);

    const [isFiltroPorLocal, setFiltroPorLocal] = useState();
    const [placeholderInput, setPlaceholderInput] = useState();

    const setTermoBusca = termo => dispatch({ type: ActionsKeys.SET_TERMO_BUSCA, termo });
    const setTermoBuscaVazio = () => dispatch({ type: ActionsKeys.SET_TERMO_BUSCA, termo: '' });
    const setPesquisando = () => dispatch({ type: ActionsKeys.TOGGLE_PESQUISANDO, isPesquisando: true });

	function selecionaEndereco(termo) {
		setTermoBusca(termo);
		setPesquisando();
	}

    const renderInput = ({ getInputProps, getSuggestionItemProps, suggestions }) => {
        return (
            <>
                <input {...getInputProps({
                    placeholder:placeholderInput, 
                    type:"text",
                    className:`col ${classInput}`,
                    name:"Search",
                    id,
                    autoFocus:true,
                    autoComplete:"off"
                })}/>

                {isFiltroPorLocal &&
                    <div className="autocomplete-maps-container">
                        {suggestions.map(suggestion => {
                            const active = suggestion.active ? 'active' : '';
                            return (
                                <div {...getSuggestionItemProps(suggestion, {className:`opcao-autocomplete-maps ${active}`})}>
                                    {IcnsAPI['localizacao']}
                                    {suggestion.description}
                                </div>
                            )
                        })}

                    </div>
                }
            </>
        );
    }

    useEffect(() => setFiltroPorLocal(filtroBusca === 0), [filtroBusca]);
    useEffect(() => setPlaceholderInput(isFiltroPorLocal ? 'Busque por CEP ou Cidade' : 'Busque por Nome ou CNPJ'), [isFiltroPorLocal]);

    return (
        <div className="row">
            <PlacesAutocomplete 
                value={termoBusca} 
                onChange={(value) => setTermoBusca(value)} 
                searchOptions={searchOptions}
                onSelect={selecionaEndereco}
                shouldFetchSuggestions={isFiltroPorLocal}
                highlightFirstSuggestion={true}
            >
                {renderInput}
            </PlacesAutocomplete>

            <span className={`excluir`} onClick={setTermoBuscaVazio}>
                {IcnsAPI['fechar']}
            </span>
        </div>
    );
}

export default GoogleApiWrapper(props => ({apiKey: props.keyMaps, libraries: ["places"], language: ["pt-BR"]}))(InputBarraBusca);