import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { 
    faAmbulance,
    faArrowLeft, 
    faBuilding, 
    faCalendar, 
    faCheckCircle,
    faChevronLeft,
    faChevronUp,
    faClock,
    faCouch, 
    faCreditCard,
    faEnvelope,
    faEye,
    faEyeSlash,
    faFan,
    faFilter,
    faInfoCircle, 
    faMapMarkerAlt,
    faNotesMedical,
    faParking,
    faPhoneAlt,
    faSearch,
    faShieldAlt, 
    faShuttleVan, 
    faSubway,
    faTh,
    faTimes,
    faWheelchair, 
    faWifi} from '@fortawesome/free-solid-svg-icons';

const fontAwesome = (icone) => <FontAwesomeIcon icon={icone} />

let IcnsAPI = {
    "padrao": fontAwesome(faCheckCircle),

    "email": fontAwesome(faEnvelope),
    "fechar": fontAwesome(faTimes),
    "filtro": fontAwesome(faFilter),
    "grid": fontAwesome(faTh),
    "horario": fontAwesome(faClock),
    "informacao": fontAwesome(faInfoCircle),
    "lupa-busca": fontAwesome(faSearch),
    "seta-voltar": fontAwesome(faArrowLeft),
    "subir": fontAwesome(faChevronUp),
    "telefone": fontAwesome(faPhoneAlt),
    "voltar": fontAwesome(faChevronLeft),
    "visualizacao-senha": fontAwesome(faEye),
    "visualizacao-senha-bloq": fontAwesome(faEyeSlash),

    // ícones cadastrados socnet:
    "local_hospital": fontAwesome(faAmbulance),
    "business": fontAwesome(faBuilding),
    "date_range": fontAwesome(faCalendar),
    "airline_seat_recline_normal": fontAwesome(faCouch),
    "credit_card": fontAwesome(faCreditCard),
    "ac_unit": fontAwesome(faFan),
    "localizacao": fontAwesome(faMapMarkerAlt),
    "loyalty": fontAwesome(faNotesMedical),
    "local_parking": fontAwesome(faParking),
    "security": fontAwesome(faShieldAlt),
    "airport_shuttle": fontAwesome(faShuttleVan),
    "train": fontAwesome(faSubway),
    "accessible": fontAwesome(faWheelchair),
    "wifi": fontAwesome(faWifi),
}

for (var i=1; i>=IcnsAPI.length; i++) {
    library.add(IcnsAPI[i]);
}

export default IcnsAPI;