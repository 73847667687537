const ActionsKeys = {
	TOGGLE_PESQUISANDO: 'TOGGLE_PESQUISANDO',
	SET_CONVENIENCIAS: 'SET_CONVENIENCIAS',
	SET_ORDENACAO: 'SET_ORDENACAO',
	SET_SERVICOS: 'SET_SERVICOS',
	SET_KEY_MAPS: 'SET_KEY_MAPS',
	SET_DISTANCIA: 'SET_DISTANCIA',
	SET_LATITUDE: 'SET_LATITUDE',
	SET_LONGITUDE: 'SET_LONGITUDE',
	SET_RESULTADOS: 'SET_RESULTADOS',
	TOGGLE_CONVENIENCIA: 'TOGGLE_CONVENIENCIA',
	LIMPA_CONVENIENCIAS: 'LIMPA_CONVENIENCIAS',
	TOGGLE_SERVICO: 'TOGGLE_SERVICO',
	LIMPA_SERVICOS: 'LIMPA_SERVICOS',
	SET_FILTRO_BUSCA: 'SET_FILTRO_BUSCA',
	SET_TERMO_BUSCA: 'SET_TERMO_BUSCA',
	SET_TIPO_VISUALIZACAO: 'SET_TIPO_VISUALIZACAO',
	SET_CREDENCIADO_PERFIL: 'SET_CREDENCIADO_PERFIL',
	TOGGLE_FILTROS: 'TOGGLE_FILTROS',
	SET_CODIGO_CARD_ATIVO: 'SET_CODIGO_CARD_ATIVO',
	SET_MARCADORES_MAPA: 'SET_MARCADORES_MAPA',
	SET_CENTRO_MAPA: 'SET_CENTRO_MAPA',
	TOGGLE_INFO_WINDOW: 'TOGGLE_INFO_WINDOW',
	SET_INFO_WINDOW: 'SET_INFO_WINDOW',
};

export default ActionsKeys;