import React, { useState, useEffect, useRef } from 'react';

export default function QuantidadeResultados({ quantidade }) {
    const refQuantidade = useRef(null);
    const [texto, setTexto] = useState();

    useEffect(() => {
        setTexto(quantidade === 1 ? 'Prestador encontrado' : 'Prestadores encontrados');
        refQuantidade.current.scrollIntoView({behavior:'smooth', block:'end', inline:'nearest'});
    }, [quantidade]);

    return (
      <span id="div-resultados-qtdd" className="col-sm-12 col-md-9" ref={refQuantidade}>
        {quantidade} {texto}
      </span>
    );
}