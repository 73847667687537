import React, { useRef } from 'react';
import IcnsAPI from '../util/iconesAPI';
import { IcnEmpresaPadrao } from '../common/icones';
import LinkPerfil from './LinkPerfil';
import { useSelector, useDispatch } from 'react-redux';
import ActionsKeys from '../store/actionsKeys';

export default function CardEmpresa({ credenciado }) {
    const dispatch = useDispatch();
    const setCodigoCardAtivo = codigo => dispatch({ type: ActionsKeys.SET_CODIGO_CARD_ATIVO, codigo });

	const {
        codigoCardAtivo,
        tipoVisualizacao,
    } = useSelector(state => state);

    const refCard = useRef(null);    
    let isMobile = () => window.innerWidth <= 600;
    let identificaModeloCard = () => isMobile() ? 0 : tipoVisualizacao;
    let cardModeloMapa = identificaModeloCard() == 0;
    let cardAtivo = (codigoCardAtivo == credenciado.codigo ) ? 'active' : '';

    const visualizacao = [
        {class: 'empresa-visualizacao-mapa', 
            tipoBtn: 'center btn-estreito',
            divExterna: 'estrutura-resultados-mapa'},
        {class: isMobile() ? 'empresa-visualizacao-mapa' : `d-flex flex-column justify-content-between empresa-visualizacao-individual`,
            tipoBtn: isMobile() ? 'center btn-alinhado btn-estreito' : 'center btn-alinhado',
            estruturaInternaCard: isMobile() ? '' : 'col align-self-stretch align-self-center center column justify-content-center', 
            divExterna: isMobile() ? 'estrutura-resultados-mapa' : 'ajusta-grid col-11'},
    ]
    
    const estruturaTrechoDadosEmpresa = {
        logo: cardModeloMapa ? 'logo-empresa col-12 col-sm-3 col-xl-2 img-container center' : 'logo-empresa img-container center',
        conteudo: cardModeloMapa ? 'dados-empresa row col-12 col-sm-9 col-xl-10' : 'dados-empresa',
        nomeEmpresa: {
            estrutura: cardModeloMapa ? 'nome-empresa' : 'nome-empresa-card', 
            nome: (cardModeloMapa && credenciado.filial) ? 'truncate filial' : 'truncate',
        },
    }
    
    const mapeamentoConveniencias = () => {
        let icones = [];
        let novoIcone = (item) => item.icone !== '' ? IcnsAPI[item.icone] : IcnsAPI["padrao"];
        let conveniencias = Object.values(credenciado.diferencialMap);
        
        if (conveniencias.length > 5) {
            for (var indiceIcone = 1; indiceIcone <= 5; indiceIcone++) icones.push(novoIcone(conveniencias[indiceIcone]));
            icones.push(<span key={conveniencias.length}>+{conveniencias.length - 5}</span>);

        } else {
            conveniencias.forEach((conveniencia) => icones.push(novoIcone(conveniencia)));
        }

        return icones;
    };

    function ChipFilial() {
        return (<span className='div-chip-filial-credenciado'>Filial</span>);
    };

    const trechoDadosEmpresa = (
        <>
            <div className={estruturaTrechoDadosEmpresa.logo}>    
                {credenciado.logo ? <img alt="Logo da empresa" src={credenciado.logo}/> : IcnEmpresaPadrao}
            </div>

            <div className={estruturaTrechoDadosEmpresa.conteudo}>

                <p className={estruturaTrechoDadosEmpresa.nomeEmpresa.estrutura} data-codigo={credenciado.codigo} data-tooltip-bottom-left={credenciado.razaoSocial}>
                    <span className={estruturaTrechoDadosEmpresa.nomeEmpresa.nome}>
                        {credenciado.razaoSocial}
                    </span>
					{(cardModeloMapa && credenciado.filial) && 
                        <ChipFilial />
                    }
				</p>

                {credenciado.enderecoVo.endereco && 
                    <span className="cidade-empresa">
                       {IcnsAPI["localizacao"]}{credenciado.enderecoVo.cidade}/{credenciado.enderecoVo.estado}
                    </span>
                }

                {credenciado.diferencialMap && 
                    <span className="card-icns-conveniencias">
                        {mapeamentoConveniencias()}
                    </span>}

                {cardModeloMapa &&
                    <LinkPerfil
                        credenciado={credenciado}
                        className={visualizacao[tipoVisualizacao].tipoBtn}
                    />
                }

            </div>
        </>
    )
    
    return (
        <div className={visualizacao[tipoVisualizacao].divExterna}>
            <section
                className={`${visualizacao[tipoVisualizacao].class} ${cardAtivo} row col empresa-dados`} 
                data-codigo={credenciado.codigo} 
                data-classificacao={credenciado.servicoMap ? Object.keys(credenciado.servicoMap).map((key)=>key) : ''} 
                data-conveniencia={credenciado.diferencialMap ? Object.keys(credenciado.diferencialMap).map((key)=>key) : ''} 
                data-horario-inicial={credenciado.horaInicial} 
                data-horario-final={credenciado.horaFinal} 
                data-latitude={credenciado.latitude} 
                data-longitude={credenciado.longitude} 
                onClick={() => setCodigoCardAtivo(credenciado.codigo)}
                ref={refCard}
            >

                { cardModeloMapa && trechoDadosEmpresa }

                { !cardModeloMapa &&
                    <>
                        <div className={visualizacao[tipoVisualizacao].estruturaInternaCard}>
                            {(!cardModeloMapa && credenciado.filial) && <ChipFilial />}
                            {trechoDadosEmpresa}
                        </div>
                        <LinkPerfil
                            credenciado={credenciado}
                            className={visualizacao[tipoVisualizacao].tipoBtn}
                        />
                    </>
                }        
            </section>
        </div>
    )       
}