import { _getDadosEndereco } from './mapaGoogleApi';

const URL_API_SOCNET = "https://api.soc.com.br/browser-credenciado";

function fetchSOCNET(parametrosJSON) {
    return fetch(URL_API_SOCNET, {
        method: "POST",
        headers: {
            "Content-Type": "text/plain"
        },
        body: JSON.stringify(parametrosJSON)
    })
    .then(resp => {
        if (resp.ok) return resp.json();
    })
    .then(json => json);
}

async function _fetchChaveMapsAPI() {
    const parametros = {acao: 'keyGoogleMaps'};
    const jsonFetch = await fetchSOCNET(parametros);

    return jsonFetch.keyGoogleMaps;
}

async function _fetchFiltrosBusca() {
    const parametros = {acao: "filtros"};
    const jsonFetch = await fetchSOCNET(parametros);

    return {
        servicos: jsonFetch.servicoList,
        conveniencias: jsonFetch.diferencialList,
    };
}

let preencherCamposLocalizacao = (endereco, keyMaps) => endereco ? _getDadosEndereco(endereco, keyMaps) : {};

async function _fetchResultadosBusca(keyMaps, filtroBusca, termoBusca, distancia) {
    let resultados = [];
    let dadosEndereco = null;
    const buscaPorLocal = parseInt(filtroBusca) === 0;

    if (buscaPorLocal) dadosEndereco = await preencherCamposLocalizacao(termoBusca, keyMaps);
    
    resultados = fetchSOCNET({
        acao: "credenciados",
        filtro : dadosEndereco ? '' : termoBusca,
        latitude : dadosEndereco && dadosEndereco.latitude ? dadosEndereco.latitude : "0",
        longitude : dadosEndereco && dadosEndereco.longitude ? dadosEndereco.longitude : "0",
        distancia: dadosEndereco && distancia ? distancia : 10
    })
    .then(json => {
        return {
            lat: dadosEndereco ? dadosEndereco.latitude : null,
            lng: dadosEndereco ? dadosEndereco.longitude : null,
            credenciados: json.credenciadoList
        };
    });

    return resultados;
}

async function _fetchPorCodigo(codigo) {
    const parametros = {
		acao: "credenciados",
		filtro : '',
		codigo: codigo,
		latitude : '0',
		longitude : '0',
	};
	const resultado = await fetchSOCNET(parametros);

	return resultado.credenciadoList[0] || {};
}

export {
    _fetchChaveMapsAPI,
    _fetchFiltrosBusca,
    _fetchResultadosBusca,
	_fetchPorCodigo,
}