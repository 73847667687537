import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ActionsKeys from '../store/actionsKeys';
import GoogleMapReact from 'google-map-react';
import CustomInfoWindow from './CustomInfoWindow';
import Marcador from './Marcador';

export default function Mapa(props) {
    const dispatch = useDispatch();
    const setMarcadores = marcadores => dispatch({ type: ActionsKeys.SET_MARCADORES_MAPA, marcadores });
    const setCentroMapa = latlng => dispatch({ type: ActionsKeys.SET_CENTRO_MAPA, latlng });
    const setInfoWindowVisivel = visivel => dispatch({ type: ActionsKeys.TOGGLE_INFO_WINDOW, visivel });
    const setCodigoCardAtivo = codigo => dispatch({ type: ActionsKeys.SET_CODIGO_CARD_ATIVO, codigo });

    const {
        centroMapa,
        isFiltrosExibidos,
        isInfoWindowVisivel,
		keyMaps,
        marcadores,
        resultadosFiltrados,
    } = useSelector(state => state);
   
    const [zoom, setZoom] = useState();
    
    function isCredenciadosEstadosDiferentes(credenciados) {
        const primeiroUF = credenciados[0].enderecoVo.estado;
        return credenciados.filter(credenciado => credenciado.enderecoVo.estado !== primeiroUF).length > 0;
    }

    useEffect(() => {
        if (!resultadosFiltrados.length) return;

        let credenciadosLatLng = resultadosFiltrados.filter(resultado => resultado.enderecoVo && resultado.enderecoVo.latitude !== '' && resultado.enderecoVo.longitude);

        setInfoWindowVisivel(false);
        setCodigoCardAtivo(null);
        
        setMarcadores(credenciadosLatLng.map((credenciado) => {
            return ( 
                <Marcador
                    key={credenciado.codigo}
                    credenciado={credenciado}
                    lat={parseFloat(credenciado.enderecoVo.latitude)} 
                    lng={parseFloat(credenciado.enderecoVo.longitude)}
                />
            );
        }));

        const isMarcadoresUFDiferentes = isCredenciadosEstadosDiferentes(credenciadosLatLng);
        let latCredenciado = parseFloat(credenciadosLatLng[0].enderecoVo.latitude) || 0;
        let lngCredenciado = parseFloat(credenciadosLatLng[0].enderecoVo.longitude) || 0;

        setZoom(isMarcadoresUFDiferentes ? 5 : 14);
        setCentroMapa({
            lat: latCredenciado === 0 ? props.latitude : latCredenciado,
            lng: lngCredenciado === 0 ? props.longitude : lngCredenciado,
        });
    }, [resultadosFiltrados]);

    return (
        <div className={`mapa-resultados ${isFiltrosExibidos ? '' : 'ampliar-mapa'}`}>
            <GoogleMapReact
                bootstrapURLKeys={{key: keyMaps, language:"pt-br"}}
                center={centroMapa}
                zoom={zoom}
                onZoomAnimationStart={() => setInfoWindowVisivel(false)}
                onDrag={() => setInfoWindowVisivel(false)}
            >

                {marcadores}

                {isInfoWindowVisivel && <CustomInfoWindow />}

            </GoogleMapReact>
        </div>
    );
}