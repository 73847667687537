import React from 'react';
import IcnsAPI from '../../util/iconesAPI';
import { Endereco, CidadeUF, CEP } from '../util-endereco';

function Contato(props) {
    if (!props.texto || props.texto === '') return null;

    return (
        <p className={props.className}>
            {props.icone} {props.texto}
        </p>
    );
}

export default function ItemRedePropria(props) {
    const isHorarioAtendimento = props.horaInicial && props.horaInicial !== '' && props.horaFinal && props.horaFinal !== '';

    return (
        <li className="row no-gutters">
            <div className="col-lg-8">
                <p className="txt-principal tag">{props.isFilial ? 'filial' : 'sede'}</p>

                <Endereco
                    endereco={props.enderecoJSON.endereco}
                    numero={props.enderecoJSON.numero}
                    complemento={props.enderecoJSON.complemento}
                    className='txt-secundario'
                />

                <CidadeUF 
                    cidade={props.enderecoJSON.cidade}
                    estado={props.enderecoJSON.estado}
                    className='txt-secundario small'
                />

                <CEP 
                    cep={props.enderecoJSON.cep}
                    className='txt-secundario small'
                />
            </div>

            <div className="col-lg-4 informacoes-adicionais">
                <Contato
                    texto={props.email}
                    icone={IcnsAPI['email']}
                    className='email-empresa truncate'
                />

                <Contato
                    texto={props.telefone}
                    icone={IcnsAPI['telefone']}
                    className='telefone-empresa'
                />

                {isHorarioAtendimento && 
                    <p className="horario-atendimento">
                        {IcnsAPI['horario']}{props.horaInicial} às {props.horaFinal}
                    </p>
                }
            </div>
        </li>
    );
}