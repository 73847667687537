import React, { Fragment } from 'react';
import IcnsAPI from '../../util/iconesAPI';
import { IcnBrasil } from "../../common/icones";

let quantidadeEstados;

const getTexto = () => `Atende cidades de ${quantidadeEstados} ${quantidadeEstados === 1 ? 'estado' : 'estados'}`;
const textoSegundario = "por rede credenciada";

export default function Estados(props) {
    quantidadeEstados = props.estados.length;

    return (
        <Fragment>
            <hr/>

            <div id="div-estados-empresa" className="estados-empresa row col-12" onClick={props.onClick}>
                <div className="col-lg-1 col-1">{IcnBrasil}</div>

                <div className="col-lg-11 col-11">
                    <p className="txt-principal">{getTexto()} {IcnsAPI['informacao']}</p>

                    <p className="txt-secundario">{textoSegundario}</p>
                </div>
            </div>
        </Fragment>
    );
}