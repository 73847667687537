import React, { useEffect, useRef } from 'react';
import IcnsAPI from '../../util/iconesAPI';
import EnderecosRede from "../areas-atendidas/EnderecosRede";
import ToggleVisualizacao from "../areas-atendidas/ToggleVisualizacao";
import FiltroLocalRede from "../areas-atendidas/FiltroLocalRede";

const titulo = "Áreas atendidas";

const subtitulo = {
    '0': 'Cidades e estados atendidos por rede própria',
    '1': 'Estados atendidos por rede credenciada',
    '2': 'Cidades e estados atendidos por rede própria e credenciada'
};

function getTipoEstruturaModal(isRedePropria, isRedeCredenciada) {
    if (isRedePropria && !isRedeCredenciada) return '0';
    if (!isRedePropria && isRedeCredenciada) return '1';
    
    return '2';
}

export default function ModalAreasAtendidas(props) {
    useEffect(() => aplicaMouseDown(props.onClick));

    const refModal = useRef(null);
    
    function aplicaMouseDown(callbackClick) {
        document.addEventListener('mousedown', (e) => {
            if (refModal.current && !refModal.current.contains(e.target)) callbackClick();
        });
    }
    
    const tipo = getTipoEstruturaModal(props.redePropria, props.redeCredenciada);

    return (
        <div data-modal="text" data-grid="row center">
            <div data-cell="shrink" ref={refModal}>
                <a href="#" data-btn="fechar-interno" onClick={props.onClick}>{IcnsAPI['fechar']}</a>

                <div className="modal-content">
                    <h6>{titulo}</h6>
                    
                    <p>{subtitulo[tipo]}</p>

                    {(tipo === '2') && 
                        <ToggleVisualizacao
                            alteracaoEstado={props.alteracaoEstado}
                            state={props.state}
                        />
                    }

                    {(props.state !== '1') && <FiltroLocalRede/>}

                    <EnderecosRede
                        credenciado={props.credenciado}
                        filiais={props.credenciado.filialList}
                        estados={props.credenciado.estadoAtendidoList}
                        state={props.state}
                    />
                </div>
            </div>
        </div>
    );
}