import React, { useState, useEffect } from 'react';
import QuantidadeResultados from './QuantidadeResultados';
import OrdenacaoResultados from './OrdenacaoResultados';
import { useSelector } from 'react-redux';

import '../style/TopoResultados.css';

export default function TopoResultados() {
	const { 
		resultadosFiltrados,
		tipoVisualizacao
	} = useSelector(state => state);

    return (
        <>
            {resultadosFiltrados.length > 0 && 
				<div id="topo-resultados-busca" className={tipoVisualizacao == 0 ? 'row col-6 visualizacao-mapa' : 'visualizacao-grade'}>
                    <QuantidadeResultados quantidade={resultadosFiltrados.length}/>

                    <OrdenacaoResultados/>
                </div>
            }
        </>
    );
}