import React, { useState } from 'react';
import ToggleBarraBusca from "./ToggleBarraBusca";
import InputBarraBusca from "./InputBarraBusca";
import BotaoBarraBusca from "./BtnBarraBusca";
import BotaoGeolocalizacao from "./BtnGeolocalizacao";
import { useSelector } from 'react-redux';

const ID_ESTILO_BARRA_BUSCA_EXTERNA = 'barra-busca-externa';
const ID_ESTILO_BARRA_BUSCA_INTERNA = 'barra-busca-resultados';

const ID_INPUT = 'ipt-busca-credenciado-2';
const ID_BOTAO = 'botao-buscar';

const estiloPrincipal = {
	id: ID_ESTILO_BARRA_BUSCA_EXTERNA,
};

const estiloSecundario = {
	id: ID_ESTILO_BARRA_BUSCA_INTERNA,
	classe: 'busca-secundaria',
};

export default function BarraBusca({ estilo, tipo }) {
	const {
		keyMaps,
		filtroBusca
	} = useSelector(state => state);
	
	const [estiloSelecionado] = useState(estilo ? estiloSecundario : estiloPrincipal);

	return (
		<div className="row col-sm-12 col-md-12 col-lg-10 col-xl-10 principal">
			<form className={`col-10 col-sm-12 row busca-geral ${estiloSelecionado.classe}`}>
				<ToggleBarraBusca
					tipo={tipo}
					idInput={ID_INPUT}
				/>

				<InputBarraBusca
					id={ID_INPUT}
					classInput={estiloSelecionado.classInput}
					keyMaps={keyMaps}
				/>

				{filtroBusca === 0 && 
					<BotaoGeolocalizacao keyMaps={keyMaps}/>
				}

				<BotaoBarraBusca
					id={ID_BOTAO}
					className={estiloSelecionado.classBtn}
					idEstilo={estiloSelecionado.id}
				/>
			</form>
		</div>
	);
}