import React from 'react';
import { MapaBrasil } from '../../common/ilustracoes';
import GoogleMapReact from 'google-map-react';
import Marcador from './Marcador';
import { useSelector } from 'react-redux';

export default function Mapa(props) {
	const { keyMaps } = useSelector(state => state);

    const isLatitude = props.latitude && !props.latitude.includes('E');
    const isLongitude = props.longitude && !props.longitude.includes('E');

    if (props.mobile && (!isLatitude || !isLongitude)) return null;
    
    return (
        <div className="mapa-localizacao-empresa" id="div-mapa-localizacao">
            {isLatitude && isLongitude && 
                <GoogleMapReact
                    bootstrapURLKeys={{key: keyMaps}}
                    zoom={15}
                    center={{lat: parseFloat(props.latitude), lng: parseFloat(props.longitude)}}
                >
                    <Marcador
                        lat={parseFloat(props.latitude)}
                        lng={parseFloat(props.longitude)}
                    />
                </GoogleMapReact>
            }

            {(!isLatitude || !isLongitude) && MapaBrasil}
        </div>
    );
}