import React, { forwardRef, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { FixedSizeGrid, FixedSizeList } from 'react-window';
import Mapa from './Mapa';
import CardEmpresa from '../common/CardEmpresa';
import { IcnSemResultados } from '../common/icones';

const MENSAGEM_BUSCA_SEM_RESULTADO = 'Nenhum resultado encontrado';

export default function Resultados() {

    const {
        codigoCardAtivo,
        isFiltrosExibidos,
        resultadosFiltrados,
        tipoVisualizacao,
    } = useSelector(state => state);

    const refListaCards = useRef(null);

    let alturaResultados = window.innerHeight - 240;
    let larguraResultados = isFiltrosExibidos ? window.innerWidth*(0.75) : window.innerWidth*(0.975);

    let visualizacaoMapa = tipoVisualizacao == 0 ? true : false;
    let possuiResultados = resultadosFiltrados && resultadosFiltrados.length !== 0;

    useEffect(() => {
        let correspondenciaLista = resultadosFiltrados.findIndex(resultado => resultado.codigo === codigoCardAtivo);
        refListaCards.current && refListaCards.current.scrollToItem(correspondenciaLista, "center");
    }, [[], [codigoCardAtivo]])
    
    const Cards = ({ index, columnIndex, rowIndex, style }) => {
        let contador = columnIndex + (isFiltrosExibidos ? rowIndex*3 : rowIndex*4);
        if (resultadosFiltrados.length <= contador && !visualizacaoMapa) return null;

        return (
        <div style={style}>
            <CardEmpresa
                credenciado={visualizacaoMapa ? resultadosFiltrados[index] : resultadosFiltrados[contador]}
                key={visualizacaoMapa ? resultadosFiltrados[index].codigo : resultadosFiltrados[contador].codigo}
            />
        </div>
    )};

    const montaCardsGrid = (
        <FixedSizeGrid
            columnCount={isFiltrosExibidos ? 3 : 4}
            columnWidth={isFiltrosExibidos ? (larguraResultados/3) : (larguraResultados/4)}
            rowCount={Math.ceil(resultadosFiltrados.length / (isFiltrosExibidos ? 3 : 4))}
            rowHeight={360}
            height={alturaResultados}
            width={larguraResultados}
            className={"row col estrutura-card"}
        >
            {Cards}
        </FixedSizeGrid>
    );

    const montaCardsMapa = (
        <FixedSizeList
            height={alturaResultados}
            itemCount={resultadosFiltrados.length}
            itemSize={115}
            className={"estrutura-card"}
            ref={refListaCards}
        >
            {Cards}
        </FixedSizeList > 
    );
   
    return (
        <div id="conteudo-resultados" className={`row col-12`}>
            {possuiResultados && !visualizacaoMapa && montaCardsGrid}        

            {possuiResultados && visualizacaoMapa && <>{montaCardsMapa}<Mapa /></> }
            
            {!possuiResultados && 
                <div id="msg-sem-resultados" className="col-12 center">
                    {IcnSemResultados}

                    <p>{MENSAGEM_BUSCA_SEM_RESULTADO}</p>
                </div>
            }
        </div>
    )
}