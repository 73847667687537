import React from 'react';

import TogglePadrao from '../../components/Toggle';

export default function ToggleVisualizacao(props) {
    return (
        <TogglePadrao
            id="tg-visualizacao-rede"
            opcao1="Rede Própria"
            opcao2="Rede Credenciada"
            tipo="busca"
            alteracaoEstado={props.alteracaoEstado}
            defaultState={props.state}
            className="toggle-grande"
        />
    );
}