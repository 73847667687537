import React from 'react';
import IcnsAPI from '../../util/iconesAPI';

export default function HorarioAtendimentoCredenciado(props) {
    const isHorarioInicial = props.inicio && props.inicio !== '' && props.inicio !== '00:00';
    const isHorarioFinal = props.fim && props.fim !== '' && props.fim !== '00:00';

    if (!isHorarioInicial || !isHorarioFinal) return null;

    return (
        <div className="horario-atendimento row col-12">
            <span>{IcnsAPI['horario']}</span>

            <span className="conjunto-dados col-lg-10 col-10">
                <p className="txt-principal">{props.inicio} às {props.fim}</p>
            </span>
        </div>
    );
}