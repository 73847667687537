import React, { useEffect, useState } from 'react';

export default function Apresentacao(props) {
    const [truncate, setTruncate] = useState('');
    
    useEffect(() => setTruncate('truncated'), []);
    
    if (!props.texto || props.texto === '') return null;

    const toggleBtn = () => {
        truncate === "truncated" ? setTruncate('') : setTruncate('truncated');
    }

    function BtnApresentacao(props) {
        return (
            <span className="toggle-text" onClick={toggleBtn}>{props.texto}</span>
        );
    }

    const isTextoExcedeLimite = props.texto.length >= 436;
    const isTruncate = truncate === "truncated";
    
    return (
        <div className="row col-12 conteudo-central info-empresa">
            <span className={`multiline-truncate ${truncate}`}>{props.texto}</span>
            
            {isTruncate && isTextoExcedeLimite ? <BtnApresentacao texto="Leia Mais"/> : ''}

            {!isTruncate && isTextoExcedeLimite ? <BtnApresentacao texto="Leia Menos"/> : ''}
        </div>
    );
}