import React from 'react';
import IcnsAPI from '../../util/iconesAPI';

let dadosInseridos;

function formataEnderecosFiliais(filial) {
    if (dadosInseridos.includes(filial.enderecoVo.cidade)) return;
    
    dadosInseridos.push(filial.enderecoVo.cidade);

    return (
        <p className="txt-secundario" key={filial.codigo}>
            {filial.enderecoVo.cidade}/{filial.enderecoVo.estado}
        </p>
    );
}

export default function Filiais(props) {
    dadosInseridos = [];
    const filiais = props.filiais.map(filial => formataEnderecosFiliais(filial));
    
    return (
        <>
            <hr/>

            <div id="div-filiais-empresa" className="filiais-empresa col-12" onClick={props.onClick}>
                <p className="txt-principal">Filiais {IcnsAPI['informacao']}</p>

                {filiais}
            </div>
        </>
    );
}