import { createStore } from 'redux';
import processaAction from './actions';

const VALORES_INICIAIS = {
	centroMapa: {},
	codigoCardAtivo: null,
	conveniencias: [],
	convenienciasSelecionadas: [],
	credenciadoPerfil: {},
	distanciaSelecionada: 10,
	filtroBusca: 0,
	infoWindow: {},
	isCarregando: false,
	isFiltrosExibidos: true,
	isInfoWindowVisivel: false,
	isPesquisando: false,
	keyMaps: null,
	latitudeBusca: null,
	longitudeBusca: null,
	marcadores: [],
	ordenacaoSelecionada: 0,
	resultadosBusca: [],
	resultadosFiltrados: [],
	resultadosPorConveniencia: {},
	resultadosPorServico: {},
	servicos: [],
	servicosSelecionados: [],
	termoBusca: '',
	tipoVisualizacao: '0',
};

const reducer = (state = VALORES_INICIAIS, action) => processaAction(state, action);

const store = createStore(reducer);
export default store;