import React from 'react';
import IcnsAPI from '../util/iconesAPI';

const titulo = "Conveniências";

function Conveniencia(props) {
    let iconeConveniencia = props.icone && props.icone !== '' ? IcnsAPI[props.icone] : IcnsAPI["padrao"];
    return (
        <p className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 row">
            {iconeConveniencia}
            
            <span className="col-11">{props.nome}</span>
        </p>
    );
}

export default function Conveniencias(props) {
    if (!props.conveniencias || !Object.values(props.conveniencias).length) return null;
    
    const itensConveniencias = Object.values(props.conveniencias).map(conveniencia => <Conveniencia nome={conveniencia.nome} icone={conveniencia.icone}/>);

    return (
        <div className="row col-12 conteudo-central">
            <span className="txt-principal col-12">{titulo}</span>

            <span className="row col-12">
                {itensConveniencias}
            </span>
        </div>
    );
}