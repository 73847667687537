import React from 'react';

export default function ItemFiltro(props) {
    return (
        <div className="elemento-filtro">
            <label>
				<input
					type="checkbox"
					name={props.item.codigo}
					value={props.item.codigo}
					className={`filtro ${props.classe}`}
					onChange={props.onClickOpcao}
					data-filtro={`${props.tipoFiltro}-${props.item.codigo}`}
					checked={props.isChecked}
				/>

                <span className="cbx-estilizado"></span>

				<span className="nome-filtro">
					{props.item.nome}&nbsp;
					({props.quantidadeResultados})
				</span>
            </label>
        </div>
    );
}